import React, { useState, lazy, Suspense, useEffect } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import "swiper/css/navigation";
import Loader from "./Loader";
import "./indexpopup.css";

import Homebannersectoin from "./HomePageComponents/Homebannersectoin";
import ContactModalBox from "./HomePageComponents/ContactModalBox";
import HomepageWork from "./HomePageComponents/HomepageWork";
import CustomLoader from "../utils/CustomLoader";


const ExploreOurOffering = lazy(() =>
  import("./HomePageComponents/ExploreOurOffering")
);
const LatestBlog = lazy(() => import("./HomePageComponents/LatestBlog"));
const NeedAConsultation = lazy(() =>
  import("./HomePageComponents/NeedAConsultation")
);
const OurCustomerScroll = lazy(() =>
  import("./HomePageComponents/OurCustomerScroll")
);
const SolutionsWeDeliver = lazy(() =>
  import("./HomePageComponents/SolutionsWeDeliver")
);
const WhereYourImagination = lazy(() =>
  import("./HomePageComponents/WhereYourImagination")
);
const WorkflowLookLike = lazy(() =>
  import("./HomePageComponents/WorkflowLookLike")
);
const Ourclients = lazy(() => import("./Ourclients"));


function Index() {
  AOS.init();

  const [serviceTabs, setServiceTabs] = useState([
    {
      name: "Software Development Services",
      active: true,
      tab: 1,
    },
    {
      name: "ERP Software Development ",
      active: false,
      tab: 2,
    },
    {
      name: "EdTech",
      active: false,
      tab: 3,
    },
    {
      name: "UX/UI Design",
      active: false,
      tab: 4,
    },
    {
      name: "Automated Bot",
      active: false,
      tab: 5,
    },
    {
      name: "CRM Software Development",
      active: false,
      tab: 6,
    },
    {
      name: "Web Development Services",
      active: false,
      tab: 7,
    },
    {
      name: "Mobile App Development",
      active: false,
      tab: 8,
    },
    {
      name: "Ecommerce Website Development",
      active: false,
      tab: 9,
    },
    {
      name: "Digital Marketing Services",
      active: false,
      tab: 10,
    },
    {
      name: "ML and AI",
      active: false,
      tab: 11,
    },
    {
      name: "AR and VR",
      active: false,
      tab: 12,
    },
  ]);


  const activeServiceTabs = (i) => {
    const temp = serviceTabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setServiceTabs([...temp]);
  };


  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!show) {
        // Check if the modal is already shown
        setShow(true);
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://ebslon.com" />
  <title>
  Best software development company in Delhi | Ebslon Infotech
  </title>
  <meta
    name="description"
    content="Ebslon Infotech is a leading software development company in Delhi. Find best software and web designing services in Delhi NCR for ecommerce, CRM, web application solution in India at the best price."
  />
  <meta
    name="keywords"
    content="Software development company, software development, software development company near me, Mobile Apps Designing Company in India, Mobile Apps Designing Company in Delhi, Mobile Apps Designing, Mobile Apps Designing services, app designing Company in India, app designing, app designing company near me, best app designing Company"
  />
  <meta
    property="og:title"
    content="Best Software Development Company in Delhi | Ebslon Infotech"
  />
  <meta
    property="og:description" 
    content="Ebslon Infotech is a leading software development company in Delhi. Find best software and web designing services in Delhi NCR for ecommerce, CRM, web application solution in India at the best price."
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://ebslon.com" />
  <meta
    property="og:image"
    content="https://ebslon.com/static/media/logo.914bd4f2fac6515b6802.png"
  />
      </Helmet>

      <Homebannersectoin handleShow={handleShow} />

      <Suspense fallback={<CustomLoader />}>
        <Ourclients />
      </Suspense>

      <section className="services-swiper  mb-80" data-aos="fade-up">
        <div className="container">
          <div className="title-section text-center mb-5">
            <h2 className="heading mb-4">
              Web Design, App Development And Digital Marketing Company
              <span className="yellow">&nbsp;In Delhi</span>
            </h2>
            <p>
              Ebslon Infotech, established in 2016, is a visionary company
              dedicated to providing cutting-edge enterprise solutions such as
              ERP, CRM, and factory automation software to drive business growth
              and enhance operational efficiency. Our comprehensive suite of IT
              offerings includes specialized applications that address vital
              business needs, from Website Design and Development of{" "}
              <Link to="/digital-marketing-agency" className="yellow_b">
                {" "}
                Digital Marketing
              </Link>{" "}
              , and App Development. Through our commitment to strategic
              thinking, significant contributions, and agile delivery,{" "}
              <strong>Ebslon Infotech</strong> is the trusted partner for
              businesses seeking tailored, high-performance software solutions.
            </p>
            <p className="text-center mt-5 animate_buttn">
              <button
                type="button"
                onClick={handleShow}
                className="submit_buttn"
              >
                Start a Project Today
              </button>
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4 d-lg-block d-sm-block d-none">
              <ul className="tabs">
                {serviceTabs?.map((item, i) => {
                  if (item) {
                    return (
                      <li
                        key={i}
                        className={item.active ? "active" : ""}
                        onClick={() => activeServiceTabs(i)}
                      >
                        <span>{item.name}</span>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>

            <div className="col-12 col-lg-8 d-lg-block d-sm-block d-none">
              <div className="tab-description">
                {serviceTabs?.map((item, i) => {
                  if (item.active && item.tab === 1) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech's software development services are
                          designed to create custom, scalable, and robust
                          solutions that cater to the unique needs of each
                          client. Our expert team of developers, utilizing the
                          latest technologies and methodologies, collaborates
                          closely with clients to deliver software applications
                          that drive efficiency, innovation, and competitive
                          advantage.
                        </p>
                        <h6>Key USPs of our software development services:</h6>
                        <ul>
                          <li>
                            Bespoke solutions tailored to individual client
                            requirements
                          </li>
                          <li>
                            Agile development methodologies for rapid and
                            iterative progress
                          </li>
                          <li>
                            Comprehensive quality assurance to ensure high
                            performance and reliability
                          </li>
                          <li>
                            Expert team with in-depth knowledge of the latest
                            technologies and industry trends
                          </li>
                          <li>
                            Seamless integration with existing systems to
                            maximize productivity
                          </li>
                          <li>
                            Transparent communication and dedicated support
                            throughout the development lifecycle
                          </li>
                        </ul>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 2) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech specializes in ERP development
                          services, crafting tailored, comprehensive, and
                          streamlined solutions to empower businesses in
                          achieving their operational and strategic goals. Our
                          experienced team of developers harnesses the latest
                          technologies to create fully integrated ERP systems
                          that enhance efficiency, improve decision-making, and
                          simplify complex processes across the organization.
                        </p>
                        <h6>Key USPs of our ERP development services:</h6>
                        <ul>
                          <li>
                            Customized solutions aligned with specific industry
                            and business needs
                          </li>
                          <li>
                            Modular design for seamless scalability and easy
                            adaptability to changing requirements
                          </li>
                          <li>
                            End-to-end integration of core business processes
                            for improved data accuracy and real-time insights
                          </li>
                          <li>
                            Rigorous security measures to safeguard critical
                            business information
                          </li>
                          <li>
                            Expert team with extensive knowledge of ERP best
                            practices and cutting-edge technologies
                          </li>
                          <li>
                            Ongoing support and maintenance to ensure optimal
                            system performance and continuous improvement
                          </li>
                        </ul>
                        <Link to="/erp-software" className="btn_view">
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 3) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech excels in developing eLearning
                          software and EdTech solutions, designed to foster
                          engaging and effective educational experiences. Our
                          team of skilled developers leverages the latest
                          technologies to create interactive, user-friendly, and
                          innovative platforms that support learning and
                          development for both educators and learners.
                        </p>
                        <h6>
                          Key USPs of our eLearning software and EdTech
                          services:
                        </h6>
                        <ul>
                          <li>
                            Customized solutions catering to diverse learning
                            styles, goals, and educational environments
                          </li>
                          <li>
                            Immersive and interactive content to enhance
                            engagement and knowledge retention
                          </li>
                          <li>
                            Adaptive learning paths tailored to individual
                            learners' progress and needs
                          </li>
                          <li>
                            Advanced analytics and reporting tools for tracking
                            performance and facilitating data-driven
                            decision-making
                          </li>
                          <li>
                            Seamless integration with existing systems and
                            compatibility with various devices for accessible
                            learning experiences
                          </li>
                          <li>
                            Expert team with deep understanding of educational
                            trends and emerging technologies in the EdTech space
                          </li>
                        </ul>
                        <Link
                          to="/educational-app-development"
                          className="btn_view"
                        >
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 4) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech's UI/UX design services focus on
                          creating intuitive, visually appealing, and
                          user-centered interfaces that seamlessly address the
                          needs and preferences of end-users. Our skilled design
                          team adopts a holistic approach, combining empathy,
                          creativity, and problem-solving skills to craft
                          engaging digital experiences that captivate users and
                          drive customer satisfaction.
                        </p>
                        <h6>Key USPs of our UI/UX design services:</h6>
                        <ul>
                          <li>
                            User-centric design philosophy, prioritizing
                            end-user needs and expectations
                          </li>
                          <li>
                            Collaborative, iterative process involving extensive
                            research, ideation, and testing
                          </li>
                          <li>
                            Problem-solving approach, identifying pain points
                            and devising innovative solutions
                          </li>
                          <li>
                            Cohesive visual language and design elements to
                            reinforce brand identity and values
                          </li>
                          <li>
                            Accessibility and inclusivity considerations to
                            cater to diverse user groups
                          </li>
                          <li>
                            Expert team with a deep understanding of design
                            principles, emerging trends, and industry best
                            practices
                          </li>
                        </ul>
                        <Link to="/ui-and-ux-design" className="btn_view">
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 5) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech offers advanced automation bot
                          solutions for WhatsApp, Instagram, and Facebook,
                          designed to streamline lead flow, enhance marketing
                          efforts, and generate higher ROI for businesses. Our
                          proficient development team creates intelligent,
                          customized bots that effectively engage with prospects
                          and customers, providing personalized interactions and
                          driving conversions.
                        </p>
                        <h6>
                          Key USPs of our automation bot services for WhatsApp,
                          Instagram, and Facebook:
                        </h6>
                        <ul>
                          <li>
                            Customized bots tailored to your specific business
                            needs and target audience
                          </li>
                          <li>
                            Integration with existing CRM systems for seamless
                            lead management and nurturing
                          </li>
                          <li>
                            Conversational AI capabilities for natural, engaging
                            interactions with users
                          </li>
                          <li>
                            Comprehensive analytics to monitor bot performance,
                            user engagement, and campaign effectiveness
                          </li>
                          <li>
                            Data-driven marketing strategies leveraging insights
                            from user interactions to optimize ROI
                          </li>
                          <li>
                            Expert team with extensive knowledge of social media
                            platforms, automation technologies, and digital
                            marketing best practices
                          </li>
                        </ul>
                        <Link to="/whatsapp-bot" className="btn_view">
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 6) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech is dedicated to crafting
                          industry-specific CRM solutions that cater to the
                          distinct needs and challenges of diverse business
                          sectors. Our expert team of developers designs
                          customized, feature-rich systems that enhance customer
                          relationship management, streamline processes, and
                          drive sales growth by harnessing deep insights into
                          customer behavior and preferences.
                        </p>
                        <h6>Key USPs of our industry-specific CRM services:</h6>
                        <ul>
                          <li>
                            Customized solutions designed to align with specific
                            industry requirements and best practices
                          </li>
                          <li>
                            Advanced segmentation and targeting tools to
                            facilitate personalized, effective communication
                            strategies
                          </li>
                          <li>
                            Comprehensive customer data management for enriched,
                            actionable insights
                          </li>
                          <li>
                            Seamless integration with existing systems and
                            processes to boost productivity and efficiency
                          </li>
                          <li>
                            Robust analytics and reporting tools for monitoring
                            performance, identifying trends, and informing
                            strategic decisions
                          </li>
                          <li>
                            Expert team with in-depth knowledge of CRM
                            technologies, industry trends, and sector-specific
                            challenges
                          </li>
                        </ul>
                        <Link to="/CRM" className="btn_view">
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 7) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech excels in web development services,
                          creating bespoke, responsive, and high-performance
                          websites tailored to meet the unique needs of
                          businesses across various industries. Our skilled team
                          of developers employs cutting-edge technologies and
                          best practices to deliver visually appealing,
                          user-friendly, and functional web solutions that drive
                          engagement and conversions.
                        </p>
                        <h6>Key USPs of our web development services:</h6>
                        <ul>
                          <li>
                            Customized website designs reflecting your brand
                            identity and business goals
                          </li>
                          <li>
                            Responsive and mobile-friendly layouts for seamless
                            user experiences across devices
                          </li>
                          <li>
                            Robust backend development, ensuring stability,
                            security, and scalability
                          </li>
                          <li>
                            Integration with third-party tools and services for
                            enhanced functionality
                          </li>
                          <li>
                            SEO-friendly coding practices to boost search engine
                            visibility and organic traffic
                          </li>
                          <li>
                            Expert team with extensive knowledge of web
                            development technologies, trends, and best practices
                          </li>
                        </ul>
                        <Link to="/web-development-company-in-india" className="btn_view">
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 8) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Development: Ebslon Infotech specializes in mobile app
                          development, crafting user-centric, feature-rich, and
                          high-performance applications for Android and iOS
                          platforms. Our expert team of developers focuses on
                          creating seamless, engaging, and intuitive mobile
                          experiences that resonate with users, drive customer
                          loyalty, and elevate business growth.
                        </p>
                        <h6>
                          Key USPs of our mobile app development services:
                        </h6>
                        <ul>
                          <li>
                            Customized app development aligned with your target
                            audience and business objectives
                          </li>
                          <li>
                            Cross-platform compatibility ensuring consistent
                            user experiences on both Android and iOS devices
                          </li>
                          <li>
                            Integration with existing systems and third-party
                            services for streamlined operations
                          </li>
                          <li>
                            Rigorous quality assurance and testing to ensure
                            optimal app performance and reliability
                          </li>
                          <li>
                            Adherence to the latest design guidelines and
                            industry standards for enhanced user experiences
                          </li>
                          <li>
                            Expert team with a deep understanding of mobile app
                            development technologies, best practices, and
                            emerging trends
                          </li>
                        </ul>
                        <Link
                          to="/mobile-app-development-company"
                          className="btn_view"
                        >
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 9) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech offers comprehensive eCommerce
                          solutions, encompassing website development, mobile
                          apps, and multi-vendor models, designed to facilitate
                          seamless online shopping experiences and drive
                          business growth. Our expert team of developers crafts
                          tailored, feature-rich, and visually appealing
                          platforms that cater to the unique requirements of
                          your business and target audience, ensuring increased
                          customer satisfaction and revenue generation.
                        </p>
                        <h6>Key USPs of our eCommerce solutions:</h6>
                        <ul>
                          <li>
                            Customized eCommerce platforms aligned with your
                            brand identity and business goals
                          </li>
                          <li>
                            Mobile-friendly, responsive designs for seamless
                            shopping experiences across devices
                          </li>
                          <li>
                            Multi-vendor support enabling easy onboarding,
                            management, and collaboration with multiple sellers
                          </li>
                          <li>
                            Robust payment gateway integrations and security
                            measures for secure, hassle-free transactions
                          </li>
                          <li>
                            Advanced inventory, order, and customer management
                            tools for efficient operations
                          </li>
                          <li>
                            Expert team with extensive knowledge of eCommerce
                            technologies, trends, and best practices for
                            delivering top-notch solutions
                          </li>
                        </ul>
                        <Link
                          to="/ecommerce-app-development"
                          className="btn_view"
                        >
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 10) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Ebslon Infotech provides top-tier digital marketing
                          services, designed to elevate your online presence,
                          drive targeted traffic, and boost conversions. Our
                          team of seasoned marketing professionals develops and
                          implements data-driven, multi-channel strategies that
                          cater to the unique goals and target audience of your
                          business, ensuring maximum ROI and sustained growth.
                        </p>
                        <h6>Key USPs of our digital marketing services:</h6>
                        <ul>
                          <li>
                            Customized marketing strategies tailored to your
                            business objectives and target audience
                          </li>
                          <li>
                            Comprehensive approach encompassing SEO, SEM, social
                            media, content marketing, and email marketing
                          </li>
                          <li>
                            Data-driven decision-making leveraging advanced
                            analytics and reporting tools
                          </li>
                          <li>
                            Creative, engaging content and visual assets to
                            captivate your audience and reinforce brand identity
                          </li>
                          <li>
                            Conversion optimization techniques to maximize leads
                            and revenue generation
                          </li>
                          <li>
                            Expert team with in-depth knowledge of digital
                            marketing trends, best practices, and
                            industry-specific nuances
                          </li>
                        </ul>

                        <Link
                          to="/digital-marketing-agency"
                          className="btn_view"
                        >
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 11) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Welcome to our AI and ML Development Company, where
                          innovation meets expertise. Ebslon Infotech specialize
                          in crafting cutting-edge solutions powered by
                          Artificial Intelligence and Machine Learning
                          technologies. Our team of skilled developers and data
                          scientists harness the latest advancements to build
                          tailored solutions that drive efficiency, enhance
                          productivity, and unlock new opportunities for your
                          business. Whether you're looking to optimize
                          processes, automate tasks, or extract valuable
                          insights from your data, we're here to help you stay
                          ahead in the digital age. Explore our services and
                          let's embark on a journey to transform your ideas into
                          reality.
                        </p>
                        <h6>Key USPs of our digital marketing services:</h6>
                        <ul>
                          <li>
                            Cutting-edge Solutions: We leverage AI and ML
                            technologies to propel your business forward.
                          </li>
                          <li>
                            Expert Team: Skilled developers and data scientists
                            ensure top-notch innovation.
                          </li>
                          <li>
                            Tailored Solutions: Customized to address your
                            unique challenges and goals.
                          </li>
                          <li>
                            Efficiency and Productivity: Streamline processes
                            and enhance productivity.
                          </li>
                          <li>
                            Business Opportunities: Unlock new possibilities in
                            the digital landscape.
                          </li>
                          <li>
                            Process Optimization: Automate tasks and improve
                            workflows.
                          </li>
                          <li>
                            Data Insights: Extract valuable insights for
                            informed decision-making.
                          </li>
                          <li>
                            Innovation-driven: Continuously exploring new
                            possibilities.
                          </li>
                          <li>
                            Collaborative Approach: Work closely with clients
                            for successful outcomes.
                          </li>
                          <li>
                            Transformative Results: Turn your ideas into reality
                            for impactful success.
                          </li>
                        </ul>

                        <Link
                          to="/ai-ml-development-services-company"
                          className="btn_view"
                        >
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                  if (item.active && item.tab === 12) {
                    return (
                      <div className="tab-inner" key={i}>
                        <h3 className="heading">{item.name}</h3>
                        <p>
                          Step into a realm where reality blends with the
                          virtual at our AR/VR Development Services. Immerse
                          yourself in a world of boundless creativity and
                          innovation as we craft bespoke experiences that
                          transcend the ordinary. From interactive training
                          modules that transport learners to new dimensions, to
                          captivating marketing campaigns that defy convention,
                          we harness the power of Augmented Reality and Virtual
                          Reality to redefine the way you engage with your
                          audience. Contact Ebslon Infotech on a journey where
                          imagination knows no bounds and every experience is an
                          adventure waiting to unfold. Explore our services and
                          embark on a transformative voyage into the future of
                          immersive technology.
                        </p>
                        <h6>Key USPs of our digital marketing services:</h6>
                        <ul>
                          <li>
                            Immersive Experiences: Enter captivating worlds
                            blending reality and virtuality, crafted with
                            precision and creativity.
                          </li>
                          <li>
                            Tailored Solutions: Our AR/VR experiences are
                            personalized to your needs for an impactful journey.
                          </li>
                          <li>
                            Cutting-edge Technology: Lead with innovation using
                            our advanced AR/VR development services, leveraging
                            the latest tech and techniques.
                          </li>
                          <li>
                            Expert Team: Our passionate developers push AR/VR
                            boundaries, delivering top-notch results every time.
                          </li>
                          <li>
                            Interactive Engagement: Engage audiences deeply with
                            interactive elements that make a lasting impression.
                          </li>
                          <li>
                            Versatile Applications: From training simulations to
                            marketing, our AR/VR solutions fit diverse
                            industries and purposes.
                          </li>
                          <li>
                            Seamless Integration: Integrate AR/VR seamlessly
                            into your systems for a cohesive user experience.
                          </li>
                          <li>
                            Creative Storytelling: Craft immersive narratives
                            that captivate and resonate with users.
                          </li>
                          <li>
                            Strategic Insight: Gain valuable user insights for
                            optimizing AR/VR initiatives and maximising impact.
                          </li>
                          <li>
                            Future-proof Solutions: Scalable and adaptable, our
                            AR/VR services evolve with your business in a
                            dynamic digital landscape.
                          </li>
                        </ul>

                        <Link to="/ar-vr-app-development" className="btn_view">
                          {" "}
                          Read More <BsArrowRight />{" "}
                        </Link>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader />}>
        <ExploreOurOffering />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <WhereYourImagination />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <WorkflowLookLike />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <SolutionsWeDeliver />
      </Suspense>
      {/* 
      <Suspense fallback={<Loader />}>
        <OurCustomerScroll />
      </Suspense> */}

      <Suspense fallback={<Loader />}>
        <HomepageWork />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <LatestBlog />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <NeedAConsultation />
      </Suspense>
      {/* <CallButtn />

      <WhatsappButtn /> */}

      <ContactModalBox
        show={show}
        setShow={setShow}
        handleShow={handleShow}
        handleClose={handleClose}
      />
    </>
  );
}

export default Index;
